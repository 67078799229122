import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import Doctor from 'models/Doctor'

export type DoctorsState = {
  allDoctors: Doctor[]
}

const initialState: DoctorsState = {
  allDoctors: [],
}

const doctorsSlice = createSlice({
  name: 'doctors',
  initialState,
  reducers: {
    setDoctors: (state: DoctorsState, action: PayloadAction<Doctor[]>) => {
      state.allDoctors = action.payload
    },
  },
})

// Selectors:
export const getDoctors = (state: RootState): Doctor[] =>
  state.doctors.allDoctors

// Exports
const { actions, reducer } = doctorsSlice
export const { setDoctors } = actions
export default reducer
