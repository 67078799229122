import React, { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import SureDialog from 'components/SureDialog'
import Doctor from 'models/Doctor'

type Props = {
  doctor: Doctor
}

const DoctorBox: FC<Props> = ({ doctor }) => {
  const [optionsVisible, setOptionsVisible] = useState(false)
  // const [deleteSureVisibe, setDeleteSureVisible] = useState(false)

  return (
    <div className='flex column flex-grid-3 listCardBox'>
      <div
        className={`dropdownBackdrop ${optionsVisible && 'active'}`}
        onClick={() => setOptionsVisible(!optionsVisible)}
      />
      <div className='flex column whitebox'>
        <div className='flex column cardContent'>
          {/* <div className='flex row'>
            <div className='flex column justify-center whiteBoxEditWrapper'>
              <div>
                <div
                  onClick={() => {
                    setOptionsVisible(!optionsVisible)
                  }}
                >
                  <FontAwesomeIcon
                    size='lg'
                    color='rgba(0,0,0,.3)'
                    icon={faEllipsisH}
                  />
                </div>
                <div
                  className={`dropdownWrapper ${optionsVisible && 'active'}`}
                >
                  <ul>
                    <li
                      onClick={() => {
                        setOptionsVisible(false)
                        editDoctor(doctor)
                      }}
                    >
                      Edit
                    </li>
                    <li
                      onClick={() => {
                        setOptionsVisible(false)
                        setDeleteSureVisible(true)
                      }}
                    >
                      Delete
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className='flex column'>
            <h5>{doctor.first_name + ' ' + doctor.last_name}</h5>
            <p>
              <small>{doctor.email}</small>
            </p>
          </div>
        </div>
      </div>
      {/* <SureDialog
        active={deleteSureVisibe}
        yesAction={() => deleteDoctor(doctor)}
        toggle={() => setDeleteSureVisible(!deleteSureVisibe)}
      /> */}
    </div>
  )
}

export default DoctorBox
