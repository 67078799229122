import React, { FC, useState, useEffect } from 'react'
import Input from 'components/Input'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Patient, { CreatePatientObj, EditPatientObj } from 'models/Patient'
import Treatment from 'models/Treatment'
import { useToast } from 'hooks/useToast'

type Props = {
  selectedPatient?: Patient
  treatments: Treatment[]
  active: boolean
  onClose: () => void
  createPatient: (patient: CreatePatientObj) => void
  editPatient: (patient: EditPatientObj) => void
}

const PatientModal: FC<Props> = ({
  selectedPatient,
  treatments,
  createPatient,
  editPatient,
  onClose,
  active,
}) => {
  const [patient, setPatient] = useState<Patient>(selectedPatient)
  const { showToast } = useToast()

  const onFieldChange = (e) => {
    let { name, value } = e.target
    if (name === 'treatment_id') {
      value = Number(value)
    }
    setPatient({
      ...patient,
      [name]: value,
    })
  }

  const onDateChange = (date) => {
    setPatient({
      ...patient,
      dob: date,
    })
  }

  const onCreatePatient = () => {
    if (
      !patient.fname ||
      !patient.lname ||
      !patient.dob ||
      !patient.email ||
      !patient.treatment_id ||
      !patient.ssn
    ) {
      showToast({
        name: 'Error',
        value: 'You did not fill in all the fields',
      })
      return
    }
    createPatient({
      patient_email: patient.email,
      patient_first_name: patient.fname,
      patient_last_name: patient.lname,
      treatment_id: patient.treatment_id,
      patient_language: 'EN',
      dob: patient.dob.toISOString(),
      ssn: patient.ssn,
    })
  }

  const onEditPatient = () => {
    if (
      !patient.fname ||
      !patient.lname ||
      !patient.email ||
      !patient.treatment_id ||
      !patient.ssn
    ) {
      showToast({
        name: 'Error',
        value: 'You did not fill in all the fields',
      })
      return
    }
    editPatient({
      email: patient.status !== 'Activated' ? patient.email : undefined,
      first_name: patient.fname,
      last_name: patient.lname,
      ssn: patient.ssn,
      treatment_id: patient.treatment_id,
    })
  }
  useEffect(() => {
    setPatient(selectedPatient)
  }, [selectedPatient])

  return (
    <div className={`flex column modal ${active ? 'active' : ''}`}>
      <div onClick={onClose} className='flex column modalBackdrop' />
      <div className='flex column modalContent last-flex'>
        <div className='flex column modalContentContainer'>
          <div className='flex column modalHead'>
            <h2>{`${patient?.id ? 'Update' : 'Invite'} a Patient`}</h2>
            <p>
              {`Make sure you provide all the information needed in order to ${
                patient?.id ? 'update' : 'invite'
              } a patient`}
            </p>
          </div>
          <Input
            type='text'
            name='fname'
            value={patient?.fname}
            onChange={onFieldChange}
            label='First Name'
            placeholder='Patient First Name'
          />
          <Input
            type='text'
            name='lname'
            value={patient?.lname}
            onChange={onFieldChange}
            label='Last Name'
            placeholder='Patient Last Name'
          />
          <Input
            type='text'
            name='ssn'
            value={patient?.ssn}
            onChange={onFieldChange}
            label='Social Security Number'
            placeholder='Patient Social Security Number'
          />
          {!patient?.id && (
            <div className='flex column inputWrapper'>
              <label>Date of Birth</label>
              <DatePicker
                selected={patient?.dob}
                onChange={onDateChange}
                dateFormat='dd/MM/yyyy'
                showYearDropdown
                showMonthDropdown
              />
            </div>
          )}
          <Input
            type='email'
            disabled={patient?.status === 'Activated'}
            name='email'
            value={patient?.email}
            onChange={onFieldChange}
            label='Email'
            placeholder='Patient Email'
          />
          <div className='flex column inputWrapper'>
            <label>Treatment</label>
            <select
              name='treatment_id'
              value={patient?.treatment_id || 0}
              onChange={onFieldChange}
            >
              <option value={0}>Select a treatment</option>
              {treatments.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='flex row sessionActionRow'>
          <div className='flex column flexAuto'>
            <button className='btn btnGrey' onClick={onClose}>
              Cancel
            </button>
          </div>
          <div className='flex column'>
            {!patient?.id ? (
              <button className='btn btnGreen' onClick={onCreatePatient}>
                Create
              </button>
            ) : (
              <button className='btn btnGreen' onClick={onEditPatient}>
                Update
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PatientModal
