import React, { FC, useEffect, useState, useCallback } from 'react'
import ReactLoading from 'react-loading'
import DoctorBox from './components/DoctorBox'
import DoctorModal from './components/DoctorModal'
import Header from 'components/Header'
import ListUtilsBar from 'components/ListUtilsBar'
import { useApi } from 'hooks/useApi'
import { useToast } from 'hooks/useToast'
import { debounce } from 'lodash'
import useDidUpdateEffect from 'hooks/useDidUpdateEffect'
import EmptyListView from 'components/EmptyListView'
import Doctor from 'models/Doctor'
import { useDoctors } from 'hooks/useDoctors'
import { Permissions } from 'models/User'

const orderOptions = [
  { value: 'fname,asc', name: 'First Name Ascending' },
  { value: 'fname,desc', name: 'First Name Descending' },
  { value: 'lname,asc', name: 'Last Name Ascending' },
  { value: 'lname,desc', name: 'Last Name Descending' },
  { value: 'email,asc', name: 'Email Ascending' },
  { value: 'email,desc', name: 'Email Descending' },
]

const Doctors: FC = () => {
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor>()

  const [order, setOrder] = useState('fname,asc')
  const [filter, setFilter] = useState('All')
  const [search, setSearch] = useState('')
  const allParams = {
    order_by: order,
    filter_by: filter === 'All' ? '' : filter,
    search_by: search.length > 2 ? search : '',
  }

  const { doctors, fetchDoctors, loading: loadingDoctors } = useDoctors()
  const [createDoctorRequest, { loading: creatingDoctor }] = useApi(
    'POST',
    '/doctor'
  )
  const [setDoctorPermissionsRequest, { loading: settingPermissions }] = useApi(
    'PUT'
  )

  const { showToast } = useToast()

  const createDoctor = async (doctor: Doctor, permissions: Permissions) => {
    const res = await createDoctorRequest({ body: doctor })
    const doctorId = res?.data?.doctorId
    if (doctorId) {
      const permissionsRes = await setDoctorPermissionsRequest({
        url: `/permissions/${doctorId}`,
        body: { permissions },
      })
      if (permissionsRes?.data?.success) {
        showToast({ name: 'Success', value: 'Doctor was successfully invited' })
        setSelectedDoctor(null)
        fetchDoctors(allParams)
      }
    }
  }

  const debauncedFetch = useCallback(
    debounce((params) => {
      fetchDoctors(params)
    }, 600),
    [fetchDoctors]
  )

  useDidUpdateEffect(() => {
    debauncedFetch(allParams)
  }, [search])
  useEffect(() => {
    fetchDoctors(allParams)
  }, [order, filter])

  return (
    <div className='flex column dashboardContent'>
      <Header
        title='Doctors'
        description={`${doctors.length} Doctors Available`}
      />
      <ListUtilsBar
        pageType='Doctor'
        possibleOrders={orderOptions}
        order={order}
        filter={filter}
        setOrder={setOrder}
        setFilter={setFilter}
        search={search}
        searchFor={setSearch}
        action={() => setSelectedDoctor({ dob: new Date() })}
      />
      <div className='flex row flexWrap treatmentsRow'>
        {doctors.length !== 0 ? (
          doctors.map((doctor) => <DoctorBox doctor={doctor} key={doctor.id} />)
        ) : (
          <EmptyListView searchTerm={search} itemType='Doctors' />
        )}
      </div>
      <DoctorModal
        active={!!selectedDoctor}
        selectedDoctor={selectedDoctor}
        onClose={() => setSelectedDoctor(null)}
        createDoctor={createDoctor}
      />
      {(loadingDoctors || creatingDoctor || settingPermissions) && (
        <div className='loadingWrapper'>
          <ReactLoading type='bars' color='#ffffff' />
        </div>
      )}
    </div>
  )
}

export default Doctors
