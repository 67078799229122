import React, { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as Paths } from 'assets/img/paths.svg'
import { ReactComponent as Time } from 'assets/img/time.svg'
import moment from 'moment'
import Survey from 'models/Survey'
import { useUser } from 'hooks/useUser'

type Props = {
  survey: Survey
  exportCSV: (survey: Survey) => void
}

const SurveyBox: FC<Props> = ({ survey, exportCSV }) => {
  const [optionsVisible, setOptionsVisible] = useState(false)
  const user = useUser()

  const actions = []
  if (
    user.permissions?.surveys?.includes('EXPORT_ENCRYPTED') ||
    user.permissions?.surveys?.includes('EXPORT_DECRYPTED')
  ) {
    actions.push(
      <li
        onClick={() => {
          exportCSV(survey)
          setOptionsVisible(!optionsVisible)
        }}
      >
        Export CSV
      </li>
    )
  }

  return (
    <div className='flex column flex-grid-3 listCardBox'>
      <div
        className={`dropdownBackdrop ${optionsVisible && 'active'}`}
        onClick={() => setOptionsVisible(!optionsVisible)}
      ></div>
      <div className='flex column whitebox'>
        <div className='flex column cardContent'>
          <div className='flex row'>
            <div className='flex column flexAuto'>
              <h5>{survey.doctorSurvey_survey.name}</h5>
              <p>
                <small>{survey.surveyInfo.questions.length} Questions</small>
              </p>
            </div>
            {actions.length > 0 && (
              <div className='flex column justify-center whiteBoxEditWrapper'>
                <div>
                  <div onClick={() => setOptionsVisible(!optionsVisible)}>
                    <FontAwesomeIcon
                      size='lg'
                      color='rgba(0,0,0,.3)'
                      icon={faEllipsisH}
                    />
                  </div>
                  <div
                    className={`dropdownWrapper ${optionsVisible && 'active'}`}
                  >
                    <ul>{actions}</ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='flex row cardMeta'>
          <div className='flex row align-center'>
            <Paths />
            <p>
              <small>{`${survey.surveyInfo.paths.length} Path${
                survey.surveyInfo.paths.length > 1 ? 's' : ''
              }`}</small>
            </p>
          </div>
          <div className='flex row align-center'>
            <Time />
            <p>
              <small>{moment(survey.created_at).fromNow()}</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SurveyBox
