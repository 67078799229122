import React, { FC } from 'react'
import Header from 'components/Header'

const NoPermissions: FC = () => {
  return (
    <div className='flex column dashboardContent'>
      <Header
        title='No Permissions'
        description='This account does not have any "VIEW" permissions. Please contact the system administrator for more information.'
      />
    </div>
  )
}

export default NoPermissions
