import React, { FC, useEffect, useState, useCallback } from 'react'
import ReactLoading from 'react-loading'
import { debounce } from 'lodash'
import { useTreatments } from 'hooks/useTreatments'
import { useHistory, useRouteMatch } from 'react-router-dom'
import TreatmentBox from './components/TreatmentBox'
import Header from 'components/Header'
import ListUtilsBar from 'components/ListUtilsBar'
import Treatment from 'models/Treatment'
import { useApi } from 'hooks/useApi'
import { useToast } from 'hooks/useToast'
import useDidUpdateEffect from 'hooks/useDidUpdateEffect'
import EmptyListView from 'components/EmptyListView'

const orderOptions = [
  { value: 'name,asc', name: 'Name Ascending' },
  { value: 'name,desc', name: 'Name Descending' },
  { value: 'updated_at,asc', name: 'Last Updated' },
  { value: 'updated_at,desc', name: 'First Updated' },
  { value: 'id,asc', name: 'ID Ascending' },
  { value: 'id,desc', name: 'ID Descending' },
]
const filterOptions = [
  { value: 'All', name: 'All' },
  { value: 'is_scheduled,true', name: 'Scheduled' },
  { value: 'is_scheduled,false', name: 'Non Scheduled' },
]

const Treatments: FC = () => {
  const history = useHistory()
  const { showToast } = useToast()
  const { url } = useRouteMatch()
  const [order, setOrder] = useState('name,asc')
  const [filter, setFilter] = useState('All')
  const [search, setSearch] = useState('')
  const allParams = {
    order_by: order,
    filter_by: filter,
    search_by: search.length > 2 ? search : '',
  }
  const {
    treatments,
    loading: loadingTreatments,
    fetchTreatments,
  } = useTreatments()
  const [deleteTreatmentRequest, { loading: deleting }] = useApi('DELETE')

  const editTreatment = (treatment: Treatment) => {
    history.push(`${url}/editTreatment/${treatment.id}`)
  }

  const deleteTreatment = async (treatment: Treatment) => {
    const res = await deleteTreatmentRequest({
      url: `/treatment/${treatment.id}`,
    })
    if (res?.data?.success) {
      showToast({
        name: 'Success',
        value: 'Treatment was successfuly deleted.',
      })
      fetchTreatments(allParams)
    }
  }

  const debauncedFetch = useCallback(
    debounce((params) => {
      fetchTreatments(params)
    }, 600),
    [fetchTreatments]
  )

  useDidUpdateEffect(() => {
    debauncedFetch(allParams)
  }, [search])

  useEffect(() => {
    fetchTreatments(allParams)
  }, [order, filter])

  return (
    <div className='flex column dashboardContent'>
      <Header
        title='Treatments'
        description={`${treatments.length} Treatments Available`}
      />
      <ListUtilsBar
        pageType='Treatment'
        possibleOrders={orderOptions}
        order={order}
        setOrder={setOrder}
        filter={filter}
        setFilter={setFilter}
        filterOptions={filterOptions}
        search={search}
        searchFor={setSearch}
        action={() => history.push(`${url}/newTreatment`)}
      />
      <div className='flex row flexWrap treatmentsRow'>
        {treatments.length > 0 ? (
          treatments.map((treatment) => (
            <TreatmentBox
              treatment={treatment}
              editTreatment={editTreatment}
              deleteTreatment={deleteTreatment}
            />
          ))
        ) : (
          <EmptyListView searchTerm={search} itemType='Treatments' />
        )}
      </div>
      {(loadingTreatments || deleting) && (
        <div className='loadingWrapper'>
          <ReactLoading type='bars' color='#ffffff' />
        </div>
      )}
    </div>
  )
}

export default Treatments
