import React, { FC, InputHTMLAttributes } from 'react'
import 'assets/styles/input.scss'

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  helper?: React.ReactNode
  error?: string
}

const TextArea: FC<Props> = ({ label, error, helper, name, value, ...rest }) => {
  return (
    <div
      className={`flex column inputWrapper${error ? ' inputErrorWrapper' : ''}`}
    >
      <div className='flex row'>
        {label && <label htmlFor={name}>{label}</label>}
        {helper && (
          <div className='flex column flexAuto align-end'>{helper}</div>
        )}
      </div>
      <textarea
        name={name}
        value={value || ''}
        rows={4}
        {...rest}
      >
      </textarea>
      <span className='inputError'>{error || ''}</span>
    </div>
  )
}

export default TextArea
