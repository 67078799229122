import React, { FC, useState, useEffect } from 'react'
import Input from 'components/Input'
import ReactSVG from 'react-svg'
import './sessionImporter.scss'
import DayBubble from 'components/DayBubble'
import Switch from 'react-switch'
import Session, { SESSION_TYPE_NAMES } from 'models/Session'
import { TreatmentType } from '../TreatmentManager'
import { InterventionSessionType } from './InterventionSessions'
import { useToast } from 'hooks/useToast'

type Props = {
  isVisible: boolean
  onClose: () => void
  sessions: Session[]
  treatmentType: TreatmentType
  totalSteps: number
  onAddExtraStep: () => void
  step: number
  onSessionImported: (session: Session) => void
  interventionSessionType?: InterventionSessionType
}

const SessionImporterModal: FC<Props> = ({
  isVisible,
  sessions,
  treatmentType,
  step,
  totalSteps,
  interventionSessionType,
  onClose,
  onAddExtraStep,
  onSessionImported,
}) => {
  const [filter, setFilter] = useState('')
  const [days, setDays] = useState('')
  const [hours, setHours] = useState('')
  const [minutes, setMinutes] = useState('')
  const [everyDay, setEveryDay] = useState(false)
  const [availableOnDay, setAvailableOnDay] = useState([step])
  const { showToast } = useToast()

  const onSessionAdded = (session: Session) => {
    if (interventionSessionType) {
      const intervention_interval = `${Number(days) > 0 ? `${days}d` : ''} ${
        Number(hours) > 0 ? `${hours}h` : ''
      } ${Number(minutes) > 0 ? `${minutes}m` : ''}`.trim()
      if (intervention_interval.length === 0) {
        showToast({
          name: 'Error',
          value: `Please enter valid "${interventionSessionType}" intervals.`,
        })
        return
      }
      onSessionImported({
        id: session.id,
        intervention_interval,
        is_after_intervention_date: interventionSessionType === 'after',
        session_type_id: session.session_type,
        isExisting: true,
      })
    } else {
      onSessionImported({
        id: session.id,
        available_on_day: availableOnDay,
        session_type_id: session.session_type,
        isExisting: true,
      })
    }
    onClose()
  }

  let sessionsList = []
  if (sessions?.length > 0) {
    sessionsList = sessions
      .filter((item) => {
        const lc = item.title?.toLowerCase()
        return lc?.includes(filter.toLocaleLowerCase())
      })
      .map((item) => {
        return (
          <li className='flex row sessionImportRow'>
            <div className='flex column flexAuto'>
              <h4>{item.title}</h4>
              <p>{SESSION_TYPE_NAMES[item.session_type]}</p>
            </div>
            <div className='flex column justify-center'>
              <div
                onClick={() => onSessionAdded(item)}
                className='flex column justify-center align-center importCross'
              >
                <ReactSVG src={require('assets/img/plus.svg')} />
              </div>
            </div>
          </li>
        )
      })
  }

  const onEveryDayChange = (checked) => {
    setEveryDay(checked)
    setAvailableOnDay(
      checked
        ? Array.from({ length: totalSteps }, (value, key) => key + 1)
        : [step]
    )
  }

  const removeSessionFromDay = (day) => {
    if (availableOnDay.length === 1) {
      return
    }
    setAvailableOnDay(availableOnDay.filter((i) => i !== day))
  }

  const addSessionToDay = (day) => {
    setAvailableOnDay([...availableOnDay, day])
  }

  let daysSelector = []
  for (var i = 1; i <= totalSteps; i++) {
    if (availableOnDay?.includes(i)) {
      daysSelector.push(
        <DayBubble step={i} onClick={removeSessionFromDay} active={true} />
      )
    } else {
      daysSelector.push(
        <DayBubble step={i} onClick={addSessionToDay} active={false} />
      )
    }
  }

  useEffect(() => {
    setAvailableOnDay([step])
  }, [isVisible])

  return (
    <div
      className={`flex column modal importSessionModal ${
        isVisible && 'active'
      }`}
    >
      <div className='modalBackdrop' onClick={onClose}></div>
      <div className='flex column modalContent'>
        <div className='flex column flexAuto modalContentContainer'>
          <div className='flex column justify-center modalHead'>
            <h2>Import Session</h2>
            <p>
              You can import any session from a treatment you have created in
              the past
            </p>
          </div>
          <div className='flex modalSearch'>
            <Input
              placeholder='Search for a session...'
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
          <div className='flex column modalSessionsList'>
            <ul className='flex column importSessionsList'>
              {sessionsList.length > 0 ? (
                sessionsList
              ) : (
                <div className='flex column align-center'>
                  <h2>No available sessions</h2>
                </div>
              )}
            </ul>
          </div>
        </div>
        <div className='flex column modalActionsWrapper'>
          {interventionSessionType && (
            <div className='flex column'>
              <div className='flex column modalHead'>
                <h2>Available {interventionSessionType}</h2>
                <p>
                  This session will be available the amount of time you specify
                  before the patients intervention date
                </p>
              </div>
              <div className='flex row interventionInputRow'>
                <div className='flex column inputWrapper'>
                  <label>Days</label>
                  <input
                    value={days}
                    onChange={(e) => setDays(e.target.value)}
                    type='number'
                    placeholder='00'
                    className='interventionTimeInput'
                  />
                </div>
                <div className='flex column inputWrapper'>
                  <label>Hours</label>
                  <input
                    value={hours}
                    placeholder='00'
                    onChange={(e) => setHours(e.target.value)}
                    type='number'
                    className='interventionTimeInput'
                  />
                </div>
                <div className='flex column inputWrapper'>
                  <label>Minutes</label>
                  <input
                    value={minutes}
                    placeholder='00'
                    onChange={(e) => setMinutes(e.target.value)}
                    type='number'
                    className='interventionTimeInput'
                  />
                </div>
              </div>
            </div>
          )}
          {treatmentType === 'scheduled' && !interventionSessionType && (
            <div className='flex row justify-center availabilityWrapper'>
              <h5>Available every day</h5>
              <div className='flex column flexAuto align-end'>
                <Switch onChange={onEveryDayChange} checked={everyDay} />
              </div>
            </div>
          )}
          {treatmentType === 'scheduled' && !interventionSessionType && (
            <div className='flex column availabilityWrapper'>
              <h5>Available on</h5>
              <div className='flex row bubblesWrapper'>
                {daysSelector}
                <div className='dayBubble' onClick={onAddExtraStep}>
                  Add Day
                </div>
              </div>
            </div>
          )}
          <div className='flex row sessionActionRow'>
            <div className='flex column flexAuto'>
              <button className='btn btnGrey' onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionImporterModal
