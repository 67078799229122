import { useSelector, useDispatch } from 'react-redux'
import { useApi } from './useApi'
import { useCallback } from 'react'
import { setDoctors, getDoctors } from 'ducks/doctors'

export const useDoctors = () => {
  const dispatch = useDispatch()

  const [getDoctorsRequest, { loading }] = useApi('GET', '/doctors')

  const fetchDoctors = useCallback(
    async (params?) => {
      const doctorsRes = await getDoctorsRequest({ params })
      if (doctorsRes?.data) {
        dispatch(setDoctors(doctorsRes.data.doctors))
      }
    },
    [getDoctorsRequest]
  )

  return {
    doctors: useSelector(getDoctors),
    fetchDoctors,
    loading,
  }
}
