import React, { FC, useState } from 'react'
import Session, { SESSION_TYPE_NAMES } from 'models/Session'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'

type Props = {
  session: Session
  onEditSession: (session: Session) => void
  onDeleteSession: (session: Session) => void
}

const InterventionSessionTile: FC<Props> = ({
  session,
  onEditSession,
  onDeleteSession,
}) => {
  const [editSession, setEditSession] = useState(false)
  return (
    <div className='flex row flexAuto listItemWrapper align-center interventionItem'>
      <div
        className={editSession ? 'dropdownBackdrop active' : 'dropdownBackdrop'}
        onClick={() => setEditSession(!editSession)}
      ></div>
      <div className='flex column flexAuto'>
        <h4>{session.title}</h4>
      </div>
      <div className='flex column flex-grid-3'>
        <p>
          {session.intervention_interval}{' '}
          {session.is_after_intervention_date ? 'after' : 'before'}
        </p>
      </div>
      <div className='flex column flex-grid-2'>
        <p>{SESSION_TYPE_NAMES[Number(session.session_type_id)]}</p>
      </div>
      <div className='flex column justify-center whiteBoxEditWrapper'>
        <div>
          <div onClick={() => setEditSession(!editSession)}>
            <FontAwesomeIcon
              size='lg'
              color='rgba(0,0,0,.3)'
              icon={faEllipsisH}
            />
          </div>
          <div
            className={
              editSession ? 'dropdownWrapper active' : 'dropdownWrapper'
            }
          >
            <ul>
              <li
                onClick={() => {
                  onEditSession(session)
                  setEditSession(!editSession)
                }}
              >
                Edit
              </li>

              <li
                onClick={() => {
                  onDeleteSession(session)
                  setEditSession(!editSession)
                }}
              >
                Delete
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InterventionSessionTile
