import React, { FC } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Patients from 'containers/Patients/Patients'
import Treatments from 'containers/Treatments/Treatments'
import Surveys from 'containers/Surveys/Surveys'
import TreatmentManager from 'containers/TreatmentManager/TreatmentManager'
import NewSurvey from 'containers/NewSurvey/NewSurvey'
import MediaCenter from 'containers/MediaCenter/MediaCenter'
import Doctors from 'containers/Doctors/Doctors'
import SideBar from './components/SideBar'
import './dashboard.scss'
import { useToast } from 'hooks/useToast'
import NotificationToast from 'components/NotificationToast'
import { ReactComponent as DoctorsIcon } from 'assets/img/doctors.svg'
import { ReactComponent as PatientsIcon } from 'assets/img/patients.svg'
import { ReactComponent as TreatmentsIcon } from 'assets/img/treatments.svg'
import { ReactComponent as SurveysIcon } from 'assets/img/surveys.svg'
import { ReactComponent as MediaIcon } from 'assets/img/mediaCenter.svg'
import User from 'models/User'
import { useUser } from 'hooks/useUser'
import NoPermissions from './components/NoPermissions'

type DashboardPage = {
  path: string
  component: React.FC
  icon: React.ReactElement
  title: string
}

export const getDashboardPages = (user: User): DashboardPage[] => {
  const pages: DashboardPage[] = []
  if (user.isAdmin) {
    pages.push({
      path: '/dashboard/doctors',
      component: Doctors,
      icon: <DoctorsIcon />,
      title: 'Doctors',
    })
  }
  if (user.permissions?.patients?.includes('VIEW')) {
    pages.push({
      path: '/dashboard/patients',
      component: Patients,
      icon: <PatientsIcon />,
      title: 'Patients',
    })
  }
  if (user.permissions?.treatments?.includes('VIEW')) {
    pages.push({
      path: '/dashboard/treatments',
      component: Treatments,
      icon: <TreatmentsIcon />,
      title: 'Treatments',
    })
  }
  if (user.permissions?.surveys?.includes('VIEW')) {
    pages.push({
      path: '/dashboard/surveys',
      component: Surveys,
      icon: <SurveysIcon />,
      title: 'Surveys',
    })
  }
  if (user.permissions?.assets?.includes('VIEW')) {
    pages.push({
      path: '/dashboard/media',
      component: MediaCenter,
      icon: <MediaIcon />,
      title: 'Media Center',
    })
  }

  if (pages.length === 0) {
    pages.push({
      path: '/dashboard/noPermissions',
      component: NoPermissions,
      icon: null,
      title: '',
    })
  }
  return pages
}

const Dashboard: FC = () => {
  const { notification, hideToast } = useToast()
  const user = useUser()
  const dashboardPages = getDashboardPages(user)

  return (
    <div className='flex row dashboardWrapper'>
      <SideBar />
      <div className='flex column flexAuto dashboardOverflowContainer'>
        {notification?.name && (
          <NotificationToast notification={notification} onClick={hideToast} />
        )}
        <Switch>
          <Route exact path='/dashboard/'>
            <Redirect to={dashboardPages[0].path} />
          </Route>
          {dashboardPages.map((page) => (
            <Route exact path={page.path} component={page.component} />
          ))}
          <Route
            exact
            path='/dashboard/treatments/newTreatment'
            component={TreatmentManager}
          />
          <Route
            exact
            path='/dashboard/treatments/editTreatment/:id'
            component={TreatmentManager}
          />
          <Route
            exact
            path='/dashboard/surveys/newSurvey'
            component={NewSurvey}
          />
        </Switch>
      </div>
    </div>
  )
}

export default Dashboard
