import React, { FC, useState } from 'react'
import axios from 'axios'
import jsonwebtoken from 'jsonwebtoken'
import { useApi } from 'hooks/useApi'
import { useToast } from 'hooks/useToast'
import { useDispatch } from 'react-redux'
import { setUser } from 'ducks/user'
import User from 'models/User'
import { ReactComponent as Logo } from 'assets/img/humanitas/logo.svg'
import './login.scss'
import NotificationToast from 'components/NotificationToast'
import LoginForm from './components/LoginForm'
import TwoFactorAuthForm from './components/TwoFactorAuthForm'

const Login: FC = () => {
  const { notification, hideToast, showToast } = useToast()
  const dispatch = useDispatch()
  const [tempUser, setTempUser] = useState<User>()

  const [loginRequest, { loading: loggingIn }] = useApi('POST', '/login')
  const [validate2FATokenRequest, { loading: validatingToken }] = useApi(
    'POST',
    '/2facauth_validate'
  )

  const performLogin = async (email: string, password: string) => {
    const res = await loginRequest({ body: { email, password } })
    const userRes: User = res?.data
    if (!userRes?.jwt) return

    const jwtPayload: any = jsonwebtoken.decode(userRes?.jwt)
    if (!jwtPayload.isDoctor) {
      showToast({
        name: 'Error',
        value: 'Contact your doctor to access the app',
      })
      return
    }

    axios.defaults.headers.common['Authorization'] = `Bearer ${userRes.jwt}`

    if (jwtPayload.isAuthorized) {
      // TODO: Temporary dispatch the user here, instead of going through 2FA if the user is authorized
      dispatch(setUser(userRes));
    } else {
      setTempUser(userRes);
    }
  }

  const validate2FAToken = async (token: string) => {
    const res = await validate2FATokenRequest({ body: { token } })
    const userRes: User = res?.data
    if (!userRes?.jwt) {
      setTempUser(null)
      return
    }

    const validatedUser = { ...tempUser, ...userRes }
    localStorage.setItem('user', JSON.stringify(validatedUser))
    dispatch(setUser(validatedUser))
  }

  return (
    <div className='flex row containerFluid authWrapper'>
      {notification?.name && (
        <NotificationToast notification={notification} onClick={hideToast} />
      )}
      <div className='flex column flex-grid-6 justify-center align-center authBanner'>
        <div className='flex column flex-grid-10 last-flex'>
          <div className='flex row logoWrapper'>
            <Logo />
          </div>
          <h1>Welcome to </h1>
          <h1>Humanitas Percorsi</h1>
          <p>Your patient management system</p>
        </div>
      </div>
      {tempUser ? (
        <TwoFactorAuthForm user={tempUser} onSubmit={validate2FAToken} />
      ) : (
        <LoginForm onSubmit={performLogin} />
      )}
    </div>
  )
}

export default Login
