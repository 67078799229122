import React, { FC, useState, useEffect } from 'react'
import QRCode from 'qrcode'
import User from 'models/User'
import { useApi } from 'hooks/useApi'
import { useToast } from 'hooks/useToast'
import Input from 'components/Input'

type Props = {
  user: User
  onSubmit: (token: string) => void
}

const TwoFactorAuthForm: FC<Props> = ({ user, onSubmit }) => {
  const [qrCodeData, setQRCodeData] = useState<string>()
  const [token, setToken] = useState('')
  const { showToast } = useToast()
  const [fetch2FASecretRequest, { loading }] = useApi(
    'POST',
    '/2facauth_secret'
  )

  const fetch2FASecret = async () => {
    const res = await fetch2FASecretRequest()
    QRCode.toDataURL(res.data.qrcode_url, (err, qrData) => {
      if (err) {
        showToast({
          name: 'Error',
          value: 'Error while trying to setup your two factor authentication.',
        })
        return
      }
      setQRCodeData(qrData)
    })
  }

  const onSubmitPress = () => {
    if (token.length === 0) {
      showToast({
        name: 'Error',
        value: 'Please enter a valid token.',
      })
      return
    }
    onSubmit(token)
  }

  useEffect(() => {
    if (!user.isTwoFactAuthEnabled) {
      fetch2FASecret()
    }
  }, [])

  return (
    <div className='flex column flex-grid-6 last-flex justify-center align-center mainBg'>
      <div className='flex column flex-grid-10 last-flex'>
        <h3>Two factor authentication</h3>
        {!user.isTwoFactAuthEnabled && (
          <div className='flex row'>
            <h4>Scan this QR code</h4>
            <img src={qrCodeData} />
          </div>
        )}
        <div className='flex column whitebox'>
          <Input
            type='number'
            value={token}
            onChange={(e) => {
              setToken(e.target.value)
            }}
            name='token'
            label='Google Authenticator Token'
            placeholder='*** ***'
          />
        </div>
        <input
          type='submit'
          className='btn btnGreen align-self-end'
          value='Continue'
          onClick={onSubmitPress}
        />
      </div>
    </div>
  )
}

export default TwoFactorAuthForm
