import { useSelector, useDispatch } from 'react-redux'
import { getAllMedia, setMedia } from 'ducks/media'
import { useApi } from './useApi'
import { useCallback } from 'react'

export const useAllMedia = () => {
  const dispatch = useDispatch()
  const [getAllMediaRequest, { loading }] = useApi('GET', '/asset')

  const fetchAllMedia = useCallback(
    async (params?) => {
      const res = await getAllMediaRequest({ params })
      if (res?.data) {
        dispatch(setMedia(res.data))
      }
    },
    [getAllMediaRequest, dispatch]
  )

  return {
    allMedia: useSelector(getAllMedia),
    fetchAllMedia,
    loading,
  }
}
