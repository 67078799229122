import React, { FC, useState, useEffect } from 'react'
import Session from 'models/Session'
import ScheduledDay from './ScheduledDay'
import { TreatmentStep } from '../TreatmentManager'
import InterventionSessions, {
  InterventionSessionType,
} from './InterventionSessions'

type Props = {
  steps: TreatmentStep[]
  view: boolean
  isInterventionActive: boolean
  intervetntionSessions?: Session[]
  addStep: (step: number) => void
  deleteStep: (step: number) => void
  onEditSession: (session: Session) => void
  onEditInterventionSession: (session: Session) => void
  onDeleteSession: (session: Session, step: number) => void
  onDeleteInterventionSession: (session: Session) => void
  importSession: (step: number) => void
  createSession: (step: number) => void
  importInterventionSession: (type: InterventionSessionType) => void
  createInterventionSession: (type: InterventionSessionType) => void
  onReorderSessions: (
    step: number,
    startIndex: number,
    endIndex: number
  ) => void
}

const ScheduledTreatment: FC<Props> = ({
  steps,
  view,
  isInterventionActive,
  intervetntionSessions,
  addStep,
  deleteStep,
  onDeleteSession,
  onDeleteInterventionSession,
  onEditSession,
  onEditInterventionSession,
  importSession,
  createSession,
  importInterventionSession,
  createInterventionSession,
  onReorderSessions,
}) => {
  let [isInterventionTab, setInterventionTab] = useState(false)

  let scheduleList = steps.map((step) => (
    <ScheduledDay
      step={step}
      importSession={importSession}
      createSession={createSession}
      onDeleteSession={onDeleteSession}
      onEditSession={onEditSession}
      onReorderSessions={onReorderSessions}
      addStep={addStep}
      deleteStep={deleteStep}
    />
  ))

  useEffect(() => {
    setInterventionTab(isInterventionActive ? true : false)
  }, [isInterventionActive])

  return (
    <div>
      {isInterventionActive && (
        <div className='flex row interventionTabs'>
          <div
            className={`flex column interventionTab ${
              isInterventionTab ? '' : 'active'
            }`}
            onClick={() => setInterventionTab(false)}
          >
            Treatment Schedule
          </div>
          <div
            className={`flex column interventionTab ${
              !isInterventionTab ? '' : 'active'
            }`}
            onClick={() => setInterventionTab(true)}
          >
            Intervention Schedule
          </div>
        </div>
      )}

      {isInterventionTab ? (
        <InterventionSessions
          sessions={intervetntionSessions}
          onCreateSession={createInterventionSession}
          onImportSession={importInterventionSession}
          onEditSession={onEditInterventionSession}
          onDeleteSession={onDeleteInterventionSession}
        />
      ) : (
        <div
          className={`flex column flex-grid-12 last-flex ${
            view ? 'calendarView' : ''
          }`}
        >
          {scheduleList}
        </div>
      )}
      {steps.length === 0 && (
        <div className='flex column flexAuto align-center listItemWrapper'>
          <h3>No Steps in Schedule</h3>
          <p>There are no steps in this Treatment Schedule yet</p>
        </div>
      )}
    </div>
  )
}

export default ScheduledTreatment
