import React, { FC, useEffect, useState, useCallback } from 'react'
import ReactLoading from 'react-loading'
import Papa from 'papaparse'
import fileDownload from 'react-file-download'
import { usePatients } from 'hooks/usePatients'
import PatientBox from './components/PatientBox'
import PatientModal from './components/PatientModal'
import Header from 'components/Header'
import ListUtilsBar from 'components/ListUtilsBar'
import { useTreatments } from 'hooks/useTreatments'
import { useApi } from 'hooks/useApi'
import { useToast } from 'hooks/useToast'
import Patient, { CreatePatientObj, EditPatientObj } from 'models/Patient'
import config from 'api/config'
import { useUser } from 'hooks/useUser'
import { debounce } from 'lodash'
import useDidUpdateEffect from 'hooks/useDidUpdateEffect'
import EmptyListView from 'components/EmptyListView'

const orderOptions = [
  { value: 'fname,asc', name: 'First Name Ascending' },
  { value: 'fname,desc', name: 'First Name Descending' },
  { value: 'lname,asc', name: 'Last Name Ascending' },
  { value: 'lname,desc', name: 'Last Name Descending' },
  { value: 'email,asc', name: 'Email Ascending' },
  { value: 'email,desc', name: 'Email Descending' },
]
const filterOptions = [
  { value: 'All', name: 'All' },
  { value: 'status,Activated', name: 'Activated' },
  { value: 'status,Not-Activated', name: 'Not-Activated' },
]

const Patients: FC = () => {
  const {
    treatments,
    fetchTreatments,
    loading: loadingTreatments,
  } = useTreatments()
  const [selectedPatient, setSelectedPatient] = useState<Patient>()
  const [loadingCSV, setLoadingCSV] = useState(false)
  const [order, setOrder] = useState('fname,asc')
  const [filter, setFilter] = useState('All')
  const [search, setSearch] = useState('')
  const allParams = {
    order_by: order,
    filter_by: filter === 'All' ? '' : filter,
    search_by: search.length > 2 ? search : '',
  }
  const { patients, fetchPatients, loading: loadingPatients } = usePatients()
  const [createPatientRequest, { loading: registeringPatient }] = useApi(
    'POST',
    '/doctor/register_patient'
  )
  const [editPatientRequest, { loading: editingPatient }] = useApi(
    'PUT',
    `/doctor/patient/${selectedPatient?.id}`
  )
  const [getPatientInfoRequest, { loading: loadingPatientInfo }] = useApi('GET')
  const [deletePatientRequest, { loading: deletingPatient }] = useApi('DELETE')
  const { showToast } = useToast()
  const user = useUser()

  const exportPatientCSV = (patient: Patient) => {
    setLoadingCSV(true)
    Papa.parse<any>(
      `${config.api_host}/treatment_patient/${patient.user_treatment_id}/export`,
      {
        download: true,
        downloadRequestHeaders: {
          Authorization: `Bearer ${user.jwt}`,
        },
        error: () => {
          showToast({
            name: 'Error',
            value: 'There is no data yet or something went wrong',
          })
          setLoadingCSV(false)
        },
        complete: (results) => {
          fileDownload(
            Papa.unparse(results.data),
            `patient_${patient.user_treatment_id}.csv`
          )
          setLoadingCSV(false)
        },
      }
    )
  }

  const deletePatient = async (patient: Patient) => {
    const res = await deletePatientRequest({
      url: `/doctor/patient/${patient.id}`,
    })
    if (res?.data?.success) {
      showToast({ name: 'Success', value: 'Patient was successfully deleted' })
      fetchPatients(allParams)
    }
  }

  const editPatient = async (patient: EditPatientObj) => {
    const res = await editPatientRequest({ body: patient })
    if (res?.data?.success) {
      showToast({ name: 'Success', value: 'Patient was successfully updated' })
      setSelectedPatient(null)
      fetchPatients(allParams)
    }
  }

  const createPatient = async (patient: CreatePatientObj) => {
    const res = await createPatientRequest({ body: patient })
    if (res?.data?.success) {
      showToast({ name: 'Success', value: 'Patient was successfully invited' })
      setSelectedPatient(null)
      fetchPatients(allParams)
    }
  }

  const onEditPatient = async (patient: Patient) => {
    const res = await getPatientInfoRequest({
      url: `/doctor/patient/${patient.id}`,
    })
    setSelectedPatient({
      ...patient,
      ssn: res?.data?.personalInfo?.ssn,
      treatment_id: treatments.find((t) => t.name === patient.treatment)?.id,
    })
  }

  const debauncedFetch = useCallback(
    debounce((params) => {
      fetchPatients(params)
    }, 600),
    [fetchPatients]
  )

  useDidUpdateEffect(() => {
    debauncedFetch(allParams)
  }, [search])
  useEffect(() => {
    fetchPatients(allParams)
  }, [order, filter])
  useEffect(() => {
    fetchTreatments()
  }, [])

  return (
    <div className='flex column dashboardContent'>
      <Header
        title='Patients'
        description={`${patients.length} Patients Available`}
      />
      <ListUtilsBar
        pageType='Patient'
        possibleOrders={orderOptions}
        order={order}
        filter={filter}
        setOrder={setOrder}
        setFilter={setFilter}
        filterOptions={filterOptions}
        search={search}
        searchFor={setSearch}
        action={() => setSelectedPatient({ dob: new Date() })}
      />
      <div className='flex row flexWrap treatmentsRow'>
        {patients.length !== 0 ? (
          patients.map((patient) => (
            <PatientBox
              patient={patient}
              exportPatientCSV={exportPatientCSV}
              editPatient={onEditPatient}
              deletePatient={deletePatient}
              key={patient.id}
            />
          ))
        ) : (
          <EmptyListView searchTerm={search} itemType='Patients' />
        )}
      </div>
      <PatientModal
        active={!!selectedPatient}
        selectedPatient={selectedPatient}
        treatments={treatments}
        onClose={() => setSelectedPatient(null)}
        createPatient={createPatient}
        editPatient={editPatient}
      />
      {(loadingPatients ||
        loadingTreatments ||
        registeringPatient ||
        loadingPatientInfo ||
        deletingPatient ||
        loadingCSV ||
        editingPatient) && (
        <div className='loadingWrapper'>
          <ReactLoading type='bars' color='#ffffff' />
        </div>
      )}
    </div>
  )
}

export default Patients
