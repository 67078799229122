import React, { FC, useState } from 'react'
import { NavLink } from 'react-router-dom'

import './sidebar.scss'
import { ReactComponent as LogoSmall } from 'assets/img/humanitas/logoSmall.svg'
import { ReactComponent as ExpandMenu } from 'assets/img/expandMenu.svg'
import { ReactComponent as ShrinkMenu } from 'assets/img/shirnkMenu.svg'
import { useUser } from 'hooks/useUser'
import { getDashboardPages } from '../Dashboard'

const SideBar: FC = () => {
  const [expanded, setExpanded] = useState(false)
  const user = useUser()

  return (
    <div
      className={`flex column whitebox sidebarWrapper${
        expanded ? ' expanded' : ''
      }`}
    >
      <div className='flex column flexAuto sidebarContent'>
        <div className='flex row justify-center logoWrapper'>
          <LogoSmall />
        </div>
        <ul>
          {getDashboardPages(user).map((page) => (
            <li>
              <NavLink activeClassName='active' to={page.path}>
                {page.icon}
                <span>{page.title}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div className='flex column align-center'>
        <ul>
          <li onClick={() => setExpanded(!expanded)}>
            {expanded ? <ShrinkMenu /> : <ExpandMenu />}
            <span>Shrink Menu</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SideBar
