import React, { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import Patient from 'models/Patient'
import SureDialog from 'components/SureDialog'
import moment from 'moment'
import { ReactComponent as Calendar } from 'assets/img/calendar.svg'
import { ReactComponent as Time } from 'assets/img/time.svg'
import { useUser } from 'hooks/useUser'

type Props = {
  patient: Patient
  exportPatientCSV: (patient: Patient) => void
  editPatient: (patient: Patient) => void
  deletePatient: (patient: Patient) => void
}

const PatientBox: FC<Props> = ({
  patient,
  exportPatientCSV,
  editPatient,
  deletePatient,
}) => {
  const [optionsVisible, setOptionsVisible] = useState(false)
  const [deleteSureVisibe, setDeleteSureVisible] = useState(false)
  const user = useUser()

  const actions = []
  if (
    user.permissions?.patients?.includes('EXPORT_ENCRYPTED') ||
    user.permissions?.patients?.includes('EXPORT_DECRYPTED')
  ) {
    actions.push(
      <li
        onClick={() => {
          setOptionsVisible(false)
          exportPatientCSV(patient)
        }}
      >
        Export CSV
      </li>
    )
  }
  if (user.permissions?.patients?.includes('EDIT')) {
    actions.push(
      <li
        onClick={() => {
          setOptionsVisible(false)
          editPatient(patient)
        }}
      >
        Edit
      </li>
    )
  }
  if (user.permissions?.patients?.includes('DELETE')) {
    actions.push(
      <li
        onClick={() => {
          setOptionsVisible(false)
          setDeleteSureVisible(true)
        }}
      >
        Delete
      </li>
    )
  }

  return (
    <div className='flex column flex-grid-3 listCardBox'>
      <div
        className={`dropdownBackdrop ${optionsVisible && 'active'}`}
        onClick={() => setOptionsVisible(!optionsVisible)}
      />
      <div className='flex column whitebox'>
        <div className='flex column cardContent'>
          <div className='flex row'>
            <div className='flex column flexAuto align-start'>
              <div
                className={
                  patient.status === 'Activated'
                    ? 'cardPill activePill'
                    : 'cardPill'
                }
              >
                {patient.status}
              </div>
            </div>
            {actions.length > 0 && (
              <div className='flex column justify-center whiteBoxEditWrapper'>
                <div>
                  <div
                    onClick={() => {
                      setOptionsVisible(!optionsVisible)
                    }}
                  >
                    <FontAwesomeIcon
                      size='lg'
                      color='rgba(0,0,0,.3)'
                      icon={faEllipsisH}
                    />
                  </div>
                  <div
                    className={`dropdownWrapper ${optionsVisible && 'active'}`}
                  >
                    <ul>{actions}</ul>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='flex column'>
            <h5>{patient.fname + ' ' + patient.lname}</h5>
            <p>
              <small>{patient.email}</small>
            </p>
          </div>
        </div>
        <div className='flex row cardMeta'>
          <div className='flex row align-center'>
            <Calendar />
            <p>
              <small>{patient.treatment}</small>
            </p>
          </div>
          <div className='flex row align-center'>
            <Time />
            <p>
              <small>{moment(patient.date_created).fromNow()}</small>
            </p>
          </div>
        </div>
      </div>
      <SureDialog
        active={deleteSureVisibe}
        yesAction={() => deletePatient(patient)}
        toggle={() => setDeleteSureVisible(!deleteSureVisibe)}
      />
    </div>
  )
}

export default PatientBox
