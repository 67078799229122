import React, { FC } from 'react'
import { ReactComponent as EmptyIcon } from 'assets/img/humanitas/empty.svg'

type Props = {
  itemType: string
  searchTerm: string
}

const EmptyListView: FC<Props> = ({ itemType, searchTerm }) => {
  return (
    <div className='flex column flex-grid-12 last-flex'>
      <div className='flex column whitebox flexAuto align-center emptyBox'>
        <EmptyIcon />
        <h5>
          No {itemType}
          {searchTerm.length > 3 && ` containing "${searchTerm}"`}
        </h5>
        <p>
          <small>
            {`There are no ${itemType.toLowerCase()} ${
              searchTerm.length < 3
                ? 'yet. Feel free to create one in order to get started.'
                : 'for this search.'
            }`}
          </small>
        </p>
      </div>
    </div>
  )
}

export default EmptyListView
