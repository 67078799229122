import React, { FC } from 'react'
import Survey from 'models/Survey'
import Input from 'components/Input'
import { TreatmentType, TreatmentUpdateObject } from '../TreatmentManager'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTh } from '@fortawesome/free-solid-svg-icons'

type Props = {
  surveys: Survey[]
  isNew: boolean
  isInterventionActive?: boolean
  selectedView: boolean
  treatment: TreatmentUpdateObject
  type: TreatmentType
  onTreatmentChange: (treatment: TreatmentUpdateObject) => void
  onChangeType: (type: TreatmentType) => void
  changeView: (bool: boolean) => void
  setInterventionActive?: () => void
}

const TreatmentSidebar: FC<Props> = ({
  surveys,
  isNew,
  treatment,
  type,
  onTreatmentChange,
  onChangeType,
  changeView,
  isInterventionActive,
  selectedView,
  setInterventionActive,
}) => {
  let preSurveyName = 'Survey not set'
  let dailySurveyName = 'Survey not set'
  let postSurveyName = 'Survey not set'

  if (!isNew && surveys.length > 0) {
    if (treatment.pre_survey_id) {
      const survey = surveys.find(
        (s) => s.survey_id === treatment.pre_survey_id
      )
      preSurveyName = survey?.doctorSurvey_survey?.name
    }
    if (treatment.survey_id) {
      const survey = surveys.find((s) => s.survey_id === treatment.survey_id)
      dailySurveyName = survey?.doctorSurvey_survey?.name
    }
    if (treatment.post_survey_id) {
      const survey = surveys.find(
        (s) => s.survey_id === treatment.post_survey_id
      )
      postSurveyName = survey?.doctorSurvey_survey?.name
    }
  }

  const onTreatmentFieldChange = (e) => {
    let { name, value } = e.target
    if (
      name === 'pre_survey_id' ||
      name === 'survey_id' ||
      name === 'post_survey_id'
    ) {
      value = Number(value)
    }
    onTreatmentChange({
      ...treatment,
      [name]: value,
    })
  }

  const surveysList = surveys.map((item) => {
    return (
      <option value={item.survey_id}>
        {item.doctorSurvey_survey && item.doctorSurvey_survey.name}
      </option>
    )
  })

  return (
    <div className='flex column treatmentSidebar'>
      <div className='flex column whitebox'>
        <div className='flex column flexAuto'>
          <div className='flex column treatmentSidebarHead'>
            <h2>{`${!isNew ? 'Update' : 'Create'} a Treatment`}</h2>
            <p>
              {`Make sure you provide all the information needed in order to ${
                !isNew ? 'update' : 'invite'
              } the treatment`}
            </p>
          </div>
          {isNew && (
            <div className='flex column inputWrapper'>
              <label>Treatment Type</label>
              <select
                name='treatmentInitialSurvey'
                value={type}
                onChange={(e) => onChangeType(e.target.value as TreatmentType)}
              >
                <option value='scheduled'>Scheduled</option>
                <option value='normal'>Normal</option>
              </select>
            </div>
          )}
          <Input
            type='text'
            name='name'
            value={treatment.name}
            onChange={onTreatmentFieldChange}
            label='Treatment Name'
            placeholder='Knee Pre-Surgery'
          />
          <Input
            type='text'
            name='description'
            value={treatment.description}
            onChange={onTreatmentFieldChange}
            label='Treatment Description'
            placeholder='Pre surgery knee treatment'
          />
          {!isNew ? (
            <div className='flex column inputWrapper'>
              <label>Treatment Initial Survey</label>
              <p>{preSurveyName}</p>
            </div>
          ) : (
            <div className='flex column inputWrapper'>
              <label>Treatment Initial Survey</label>
              <select
                name='pre_survey_id'
                value={treatment.pre_survey_id}
                onChange={onTreatmentFieldChange}
              >
                <option value={null}>Select a survey</option>
                {surveysList}
              </select>
            </div>
          )}
          {!isNew ? (
            <div className='flex column inputWrapper'>
              <label>Treatment Daily Survey</label>
              <p>{dailySurveyName}</p>
            </div>
          ) : (
            <div className='flex column inputWrapper'>
              <label>Treatment Daily Survey</label>
              <select
                name='survey_id'
                value={treatment.survey_id}
                onChange={onTreatmentFieldChange}
              >
                <option value={null}>Select a survey</option>
                {surveysList}
              </select>
            </div>
          )}
          {type === 'scheduled' ? (
            !isNew ? (
              <div className='flex column inputWrapper'>
                <label>Post Treatment Survey</label>
                <p>{postSurveyName}</p>
              </div>
            ) : (
              <div className='flex column inputWrapper'>
                <label>Post Treatment Survey</label>
                <select
                  name='post_survey_id'
                  value={treatment.post_survey_id}
                  onChange={onTreatmentFieldChange}
                >
                  <option value={null}>Select a survey</option>
                  {surveysList}
                </select>
              </div>
            )
          ) : null}
          {type === 'scheduled' && (
            <div className='flex column inputWrapper'>
              <label>Intervention Date</label>
              <select
                value={isInterventionActive.toString()}
                name='interverntion_date'
                disabled={!isNew}
                onChange={setInterventionActive}
              >
                <option value='false'>Disabled</option>
                <option value='true'>Enabled</option>
              </select>
            </div>
          )}
        </div>
        {type === 'scheduled' ? (
          <div className='flex row viewSwitch modalActionsWrapper'>
            <div className='flex row flexAuto'>
              <div className='flex row align-center justify-center layoutSwitch'>
                <FontAwesomeIcon size='lg' color='#121312' icon={faTh} />
                <p>
                  <small>Layout</small>
                </p>
              </div>
              <div className='flex column flexAuto inputWrapper'>
                <select
                  value={selectedView ? 'true' : 'false'}
                  onChange={(e) =>
                    e.target.value === 'true'
                      ? changeView(true)
                      : changeView(false)
                  }
                >
                  <option value={'false'}>Column</option>
                  <option value={'true'}>Calendar</option>
                </select>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default TreatmentSidebar
