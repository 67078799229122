import React, { FC, useState } from 'react'
import { ReactComponent as LogoSmall } from 'assets/img/humanitas/logoSmall.svg'
import Input from 'components/Input'
import { useToast } from 'hooks/useToast'

type Props = {
  onSubmit: (email: string, password: string) => void
}

const LoginForm: FC<Props> = ({ onSubmit }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { showToast } = useToast()

  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const onChangePassword = (e) => {
    setPassword(e.target.value)
  }

  const onSubmitPress = async (e) => {
    e.preventDefault()
    if (!email || !password) {
      showToast({
        name: 'Error',
        value: 'We need both your email and password',
      })
      return
    }
    onSubmit(email, password)
  }

  return (
    <div className='flex column flex-grid-6 last-flex justify-center align-center mainBg'>
      <div className='flex column flex-grid-10 last-flex'>
        <div className='flex row logoWrapper'>
          <LogoSmall />
        </div>
        <h3>Sign In</h3>
        <p>Sign in to manage your content now!</p>
        <form method='post' className='flex column' onSubmit={onSubmitPress}>
          <div className='flex column whitebox'>
            <Input
              type='text'
              value={email}
              onChange={onChangeEmail}
              name='loginEmail'
              label='Your Email'
              placeholder='email@some.com'
            />
            <div className='horizontalSeparator' />
            <Input
              type='password'
              name='loginPassword'
              value={password}
              helper={<a>Forgot password?</a>}
              onChange={onChangePassword}
              label='Your Password'
              placeholder='*****'
            />
          </div>
          <input
            type='submit'
            className='btn btnGreen align-self-end'
            value='Sign In'
            onClick={onSubmitPress}
          />
        </form>
      </div>
    </div>
  )
}

export default LoginForm
