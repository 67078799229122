import React, { FC } from 'react'
import Session from 'models/Session'
import InterventionSessionTile from './InterventionSessionTile'

export type InterventionSessionType = 'after' | 'before'

type Props = {
  sessions: Session[]
  onCreateSession: (type: InterventionSessionType) => void
  onImportSession: (type: InterventionSessionType) => void
  onEditSession: (session: Session) => void
  onDeleteSession: (session: Session) => void
}

const InterventionSessions: FC<Props> = ({
  sessions,
  onCreateSession,
  onImportSession,
  onEditSession,
  onDeleteSession,
}) => {
  let beforeSessions = sessions
    .filter((s) => !s.is_after_intervention_date)
    .map((session) => (
      <InterventionSessionTile
        session={session}
        onEditSession={onEditSession}
        onDeleteSession={onDeleteSession}
      />
    ))

  let afterSessions = sessions
    .filter((s) => s.is_after_intervention_date)
    .map((session) => (
      <InterventionSessionTile
        session={session}
        onEditSession={onEditSession}
        onDeleteSession={onDeleteSession}
      />
    ))

  return (
    <div className='flex column newTreatmentScheduleDay'>
      <div className='flex column'>
        <div className='flex row align-center dayActionsRow'>
          <div className='flex column flexAuto dayHead'>
            <h3>Before</h3>
            <h5>The intervention date</h5>
          </div>
          <div className='flex row'>
            <button
              onClick={() => onImportSession('before')}
              className='btn btnGreen'
            >
              Import a Session
            </button>
            <button
              onClick={() => onCreateSession('before')}
              className='btn btnGreen'
            >
              Create a Session
            </button>
          </div>
        </div>
        <div className='flex column newTreatmentDailyList'>
          {beforeSessions.length > 0 ? (
            beforeSessions
          ) : (
            <div className='flex column listItemWrapper emptyItem'>
              <h4>No Scheduled Sessions</h4>
              <p>Please create your first session</p>
            </div>
          )}
        </div>
      </div>
      <div className='flex column afterIntervention'>
        <div className='flex row align-center dayActionsRow'>
          <div className='flex column flexAuto dayHead'>
            <h3>After</h3>
            <h5>The intervention date</h5>
          </div>
          <div className='flex row'>
            <button
              onClick={() => onImportSession('after')}
              className='btn btnGreen'
            >
              Import a Session
            </button>
            <button
              onClick={() => onCreateSession('after')}
              className='btn btnGreen'
            >
              Create a Session
            </button>
          </div>
        </div>
        <div className='flex column newTreatmentDailyList'>
          {afterSessions.length > 0 ? (
            afterSessions
          ) : (
            <div className='flex column listItemWrapper emptyItem'>
              <h4>No Scheduled Sessions</h4>
              <p>Please create your first session</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default InterventionSessions
