import React, { FC } from 'react'
import Input from 'components/Input'

type Props = {
  name: string
  onNameChange: (e: any) => void
}

const SurveySidebar: FC<Props> = ({ onNameChange, name }) => {
  return (
    <div className='flex column treatmentSidebar'>
      <div className='flex column whitebox'>
        <div className='flex column treatmentSidebarHead'>
          <h2>{`Create a Survey`}</h2>
          <p>
            {`Make sure you provide all the information needed in order to create the survey`}
          </p>
        </div>
        <Input
          type='text'
          name='name'
          value={name}
          onChange={onNameChange}
          label='Survey Name'
          placeholder='Knee Pre-Surgery'
        />
      </div>
    </div>
  )
}

export default SurveySidebar
