import { useSelector, useDispatch } from 'react-redux'
import { getAllSurveys, setSurveys } from 'ducks/surveys'
import { useApi } from './useApi'
import { useState } from 'react'

export const useSurveys = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [getSurveysRequest] = useApi('GET', '/doctor/surveys')

  const fetchSurveys = async (params?) => {
    setLoading(true)
    const res = await getSurveysRequest({ params })
    dispatch(setSurveys(res?.data?.doctorsSurveys))
    setLoading(false)
  }

  return {
    surveys: useSelector(getAllSurveys),
    fetchSurveys,
    loading,
  }
}
