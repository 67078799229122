import React, { FC } from 'react'
import { ReactComponent as Search } from 'assets/img/search.svg'
import { ReactComponent as Order } from 'assets/img/order.svg'
import { ReactComponent as Filter } from 'assets/img/filter.svg'

type Props = {
  order: string
  pageType: string
  filter?: string
  search?: string
  filterOptions?: Array<any>
  setFilter?: (filter: string) => void
  setOrder: (order: string) => void
  searchFor?: (search: string) => void
  possibleOrders: Array<any>
  action: () => void
}

const ListUtilsBar: FC<Props> = ({
  order,
  setOrder,
  possibleOrders,
  action,
  pageType,
  filterOptions,
  filter,
  setFilter,
  search,
  searchFor,
}) => (
  <div className='flex row'>
    <div className='flex row flexAuto align-center align-start'>
      <div className='flex row searchBar align-center'>
        <input
          value={search}
          className='flex row flexAuto'
          placeholder={'Search for a ' + pageType}
          onChange={(e) => searchFor(e.target.value)}
        />
        <div className='flex column justify-center iconWrapper'>
          <Search />
        </div>
      </div>
      <div className='flex row filterBar align-center'>
        <div className='flex row justify-center iconWrapper'>
          <Order />
          <p>
            <small>Sort by:</small>
          </p>
        </div>
        <select
          value={order}
          onChange={(v) => setOrder(v.target.value)}
          className='flex column flexAuto last-flex'
        >
          {possibleOrders.map((orderOption) => (
            <option value={orderOption.value} key={orderOption.value}>
              {orderOption.name}
            </option>
          ))}
        </select>
      </div>
      {filterOptions && (
        <div className='flex row filterBar align-center'>
          <div className='flex row justify-center iconWrapper'>
            <Filter />
            <p>
              <small>Show:</small>
            </p>
          </div>
          <select
            value={filter}
            onChange={(v) => setFilter(v.target.value)}
            className='flex column flexAuto last-flex'
          >
            {filterOptions.map((filterOption) => (
              <option key={filterOption.value} value={filterOption.value}>
                {filterOption.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
    <div className='flex column'>
      <button className='btnGreen btn' onClick={action}>
        {`${
          pageType === 'Patient' || pageType === 'Doctor' ? 'Invite' : 'Create'
        } ${pageType}`}
      </button>
    </div>
  </div>
)

export default ListUtilsBar
