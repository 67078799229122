import React, { FC, useState, useEffect } from 'react'
import Input from 'components/Input'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useToast } from 'hooks/useToast'
import Doctor from 'models/Doctor'
import { PERMISSIONS_OPTIONS, Permissions } from 'models/User'
import { capitalize, without } from 'lodash'

type Props = {
  selectedDoctor?: Doctor
  active: boolean
  onClose: () => void
  createDoctor: (doctor: Doctor, permissions: Permissions) => void
}

const initialPermissions = {
  patients: ['VIEW', 'EDIT', 'DELETE', 'EXPORT_DECRYPTED', 'EXPORT_ENCRYPTED'],
  treatments: [
    'VIEW',
    'EDIT',
    'DELETE',
    'ASSIGN',
    'EXPORT_DECRYPTED',
    'EXPORT_ENCRYPTED',
  ],
  surveys: ['VIEW', 'EXPORT_DECRYPTED', 'EXPORT_ENCRYPTED'],
  assets: ['VIEW'],
}

const DoctorModal: FC<Props> = ({
  selectedDoctor,
  createDoctor,
  onClose,
  active,
}) => {
  const [doctor, setDoctor] = useState<Doctor>(selectedDoctor)
  const [permissions, setPermissions] = useState<Permissions>(
    initialPermissions
  )
  const { showToast } = useToast()

  const onFieldChange = (e) => {
    let { name, value } = e.target
    setDoctor({
      ...doctor,
      [name]: value,
    })
  }

  const onDateChange = (date) => {
    setDoctor({
      ...doctor,
      dob: date,
    })
  }

  const onPermissionsChange = (e) => {
    const section = e.target.name
    setPermissions({
      ...permissions,
      [section]: e.target.checked
        ? [...permissions[section], e.target.value]
        : without(permissions[section], e.target.value),
    })
  }

  const onCreateDoctor = () => {
    if (
      !doctor.first_name ||
      !doctor.last_name ||
      !doctor.email ||
      !doctor.password ||
      !doctor.language ||
      !doctor.dob ||
      !doctor.ssn
    ) {
      showToast({
        name: 'Error',
        value: 'You did not fill in all the fields',
      })
      return
    }
    createDoctor(doctor, permissions)
  }

  useEffect(() => {
    setDoctor(selectedDoctor)
    if (selectedDoctor === null) {
      setPermissions(initialPermissions)
    }
  }, [selectedDoctor])

  return (
    <div className={`flex column modal ${active ? 'active' : ''}`}>
      <div onClick={onClose} className='flex column modalBackdrop' />
      <div className='flex column modalContent last-flex'>
        <div className='flex column modalContentContainer'>
          <div className='flex column modalHead'>
            <h2>{`${doctor?.id ? 'Update' : 'Invite'} a Doctor`}</h2>
            <p>
              {`Make sure you provide all the information needed in order to ${
                doctor?.id ? 'update' : 'invite'
              } a doctor`}
            </p>
          </div>
          <Input
            type='text'
            name='first_name'
            value={doctor?.first_name}
            onChange={onFieldChange}
            label='First Name'
            placeholder='Doctor First Name'
          />
          <Input
            type='text'
            name='last_name'
            value={doctor?.last_name}
            onChange={onFieldChange}
            label='Last Name'
            placeholder='Doctor Last Name'
          />
          <Input
            type='email'
            name='email'
            value={doctor?.email}
            onChange={onFieldChange}
            label='Email'
            placeholder='Doctor Email'
          />
          <Input
            type='text'
            name='password'
            value={doctor?.password}
            onChange={onFieldChange}
            label='Password'
            placeholder='Doctor Password'
          />
          <Input
            type='text'
            name='language'
            value={doctor?.language}
            onChange={onFieldChange}
            label='Language'
            placeholder='Doctor Language'
          />
          {!doctor?.id && (
            <div className='flex column inputWrapper'>
              <label>Date of Birth</label>
              <DatePicker
                selected={doctor?.dob}
                onChange={onDateChange}
                dateFormat='dd/MM/yyyy'
                showYearDropdown
                showMonthDropdown
              />
            </div>
          )}
          <Input
            type='text'
            name='ssn'
            value={doctor?.ssn}
            onChange={onFieldChange}
            label='Social Security Number'
            placeholder='Doctor Social Security Number'
          />
          {Object.keys(PERMISSIONS_OPTIONS).map((section) => (
            <div className='inputWrapper flex column'>
              <label>{capitalize(section)} Permissions</label>
              {PERMISSIONS_OPTIONS[section].map((permission) => (
                <div className='flex row checkboxItem'>
                  <input
                    type='checkbox'
                    name={section}
                    checked={permissions[section].includes(permission)}
                    onChange={onPermissionsChange}
                    value={permission}
                  />
                  <label htmlFor={permission}>{permission}</label>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className='flex row sessionActionRow'>
          <div className='flex column flexAuto'>
            <button className='btn btnGrey' onClick={onClose}>
              Cancel
            </button>
          </div>
          <div className='flex column'>
            <button className='btn btnGreen' onClick={onCreateDoctor}>
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DoctorModal
