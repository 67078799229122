type User = {
  id: number
  email: string
  first_name: string
  last_name: string
  language: string
  user_photo: string
  jwt: string
  isAdmin?: boolean
  isTwoFactAuthEnabled: boolean
  permissions: Permissions
}

export type Permissions = {
  patients: string[]
  treatments: string[]
  surveys: string[]
  assets: string[]
}

export const PERMISSIONS_OPTIONS = {
  patients: ['VIEW', 'EDIT', 'DELETE', 'EXPORT_DECRYPTED', 'EXPORT_ENCRYPTED'],
  treatments: [
    'VIEW',
    'EDIT',
    'DELETE',
    'ASSIGN',
    'EXPORT_DECRYPTED',
    'EXPORT_ENCRYPTED',
  ],
  surveys: ['VIEW', 'EXPORT_DECRYPTED', 'EXPORT_ENCRYPTED'],
  assets: ['VIEW'],
}

export default User
